.section-reviews {
  background-color: var(--main-up-keep-secondary);
  padding: 3em 6em;
}
.form-reviews {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container-select {
  width: 90%;
  background-color: #fff;
  padding: 1em;
  border-radius: 70px;
  display: flex;
}
.select-reviews {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-left: 5em;
  width: 100%;
  height: 4.8em;
  background: #fff;
  background-repeat: no-repeat no-repeat;
  background-position: 82% 50%, 3% 50%;
  /* background-position-x: 90%;
  background-position-y: 50%; */
  border: 1px solid var(--main-up-keep);
  border-radius: 40px;
}
.select-reviews:focus {
  outline: none;
}

.btn-reviews-form {
  margin-left: -140px;
  width: 10em;
  height: 4.8em;
  background-color: #fb9444;
  border-radius: 40px;
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.img-filter-reviews {
  width: 1.5em;
}

.btn-reviews-form:hover {
  background-color: #fff;
  color: #fb9444;
  border: 2px solid #fb9444;
}
.btn-reviews-form:hover .img-filter-reviews {
  filter: invert(62%) sepia(80%) saturate(620%) hue-rotate(329deg)
    brightness(102%) contrast(97%);
}

.btn-reviews-form span {
  font-weight: bold;
  font-size: 1.2em;
}
.container-reviews {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-carousel {
  width: 100%;
  display: flex;
  justify-content: center;
}
.carousel-review {
  width: 100%;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(63%) sepia(15%) saturate(1796%) hue-rotate(149deg)
    brightness(96%) contrast(85%);
}

.carousel-indicators .active {
  background-color: var(--main-up-keep);
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 0em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section-reviews {
    padding: 2em 0.5em;
  }
  .container-select {
    flex-direction: column;
  }
  .btn-reviews-form {
    margin-left: 0px;
    margin-top: 2px;
    width: 100%;
  }
  .select-reviews {
    height: 3em;
    background-position: 85% 50%, 7% 50%;
  }
  .btn-reviews-form {
    height: 3em;
  }
  .carousel-indicators .active {
    background-color: var(--main-up-keep)!important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .section-reviews {
    padding: 2em 3em;
  }
  .select-reviews {
    background-position: 65% 50%, 7% 50%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .select-reviews {
    background-position: 70% 50%, 5% 50%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .select-reviews {
    background-position: 80% 50%, 5% 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .section-reviews {
    padding: 3em 6em;
  }
  .select-reviews {
    background-position: 83% 50%, 3% 50%;
  }
}
