.company-services-section {
  background-color: var(--main-up-keep-secondary);
  padding: 1em 4em;
}

.buttons-companies-service {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
