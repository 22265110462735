.my-navbar {
  background-color: var(--main-up-keep-primary);
}

.navbar-img {
  margin: 0 !important;
}

.burger {
  position: absolute;
  left: 30px;
}

.btn-sign-in-nav {
  background-color: var(--main-up-keep);
  color: #fff;
  font-weight: bold;
  width: 100px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid var(--main-up-keep);
}

.btn-sign-in-nav:hover {
  background-color: #0464cc;
  border: 1px solid #0464cc;
}
.btn-sign-in-nav:active {
  -webkit-box-shadow: 0px 0px 5px 4px #1094e4;
  box-shadow: 0px 0px 5px 4px #1094e4;
}
.photo {
  display: inline-block;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}
.logo-navbar {
  width: 6em;
  height: 2.2em;
}

.container-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title-navbar {
  letter-spacing: 5px;
  font-weight: bold;
}

.mr-auto {
  flex-wrap: wrap;
}

@media only screen and (max-width: 830px) {
  .my-navbar {
    padding: 0.5rem 0.5rem !important;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .container-img-toggle {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .logo-navbar {
    width: 8em;
    height: 2.8em;
  }

  .navbar-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 330px) {
  .burger {
    left: 2px;
  }
}
