.row-section-sign-in {
    width: 100%;
    background-color: var(--main-up-keep-secondary);
}
.container-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5em 0;
    height: auto;
}
.card-sign-in {
    width: 500px;
    height: auto;
    background-color: #fff;
    border-radius: 20px;
}
.card-body-sign-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo-signin {
    width: 14em;
    height: 5em;
    margin-top: 50px;
}
.form-sign-in {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5em;
    align-items: center;
}

.input-sign-in {
    min-width: 340px;
    height: 3.5em;
    border-radius: 50px;
    padding-left: 55px;
    border: 1px solid #C0C0C0;
    margin: 27px 0;
}
.input-sign-in:focus {
    outline: none;
}

.icon-login-email{
    margin-bottom: -62px;
    margin-left: -268px;
    width: 1.2em;
    height: .8em;
    z-index: 1;
}
.icon-login-password{
    width: .7em;
    height: 1em;
    margin-bottom: -62px;
    margin-left: -268px;
    z-index: 1;
}

.forgot-pass {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-signin {
    width: 340px;
    height: 3.3em;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    border: 1px solid #fb9444;
    background-color: #fb9444;
}

.error-message {
  color: red;
  width: 100%;
  text-align: center;
}

.label-logo {
    font-weight: lighter;
    font-size: 2em;
    letter-spacing: 4px;
}

/*Media Querys*/
@media only screen and (max-width: 600px) {
    .card-sign-in {
        width: 300px;
        height: auto;
        background-color: #fff;
        border-radius: 20px;
        padding: 0 2em;
    }
    .form-sign-in {
        padding: 0 2em;
    }

    .input-sign-in {
        min-width: 280px;
        height: 3.2em;
        border-radius: 50px;
        padding-left: 40px;
        margin: 27px 0;
    }

    .btn-signin {
        width: 280px;
        height: 3.3em;
        border-radius: 50px;
        color: #fff;
        font-weight: bold;
        border: 1px solid #fb9444;
        background-color: #fb9444;
    }

    .icon-login-email{
        margin-bottom: -61px;
        margin-left: -230px;
    }
    .icon-login-password{
        margin-bottom: -61px;
        margin-left: -230px;
    }

    .error-message {
      font-size: 15px;
    }
}