.company-service-section {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.card-company-service {
    background-color: #fff;
    width: 35em;
    border-radius: 20px;
    margin-bottom: 2em;
}
.card-head-company-service {
    padding-top: 2em;
    padding-bottom: .8em;
}
.title-card-company-service {
    text-align: center;
}
.card-body-company-service {
    margin: 0em 3em;
}
.form-container-input {
    width: 100%;
    margin: 1.5em 0;
}
.input-form-company-service {
    width: 100%;
    height: 3em;
    border-radius: 30px;
    border: 1px solid #ccc;
    padding-left: 2em;
    outline: none;
}
.input-form-company-service:focus {
    border: 1px solid gray;
}
.btn-container-company-service {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
}
.btn-submit-company-service {
    margin-top: 1em;
    width: 13em;
    height: 3.3em;
    border-radius: 40px;
    color: #fff;
    background-color: #fb9444;
    border: 2px solid #fb9444;
    transition: all 0.3s;
}
.btn-submit-company-service:hover {
    border: 2px solid #fb9444;
    background-color: #fff
}
.label-btn-submit {
    font-size: 1.2em;
    font-weight: bold;
}
.btn-submit-company-service:hover .label-btn-submit {
    color: #fb9444;
    transition: all 0.3s;
}
.select-company-service {
    -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

    background: #fff;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
}