.custom-file-upload {
  padding-top: 8px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 3em;
  border-radius: 30px;
  border: 1px solid #ccc;
  padding-left: 2em;
  outline: none;
}

.upload-text-inputfile {
  margin-right: 5%;
}

.input-form-img {
  width: 100%;
  height: 3em;
  border-radius: 30px;
  border: 1px solid #ccc;
  padding-left: 2em;
  outline: none;
}

.input-form-img:focus {
  border: 1px solid gray;
}
