.input-container {
  width: 90%;
  background-color: #fff;
  padding: 1em;
  border-radius: 70px;
  display: flex;
  margin: .5rem auto;
}
.select-reviews {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-left: 11rem;
  width: 100%;
  height: 4.8em;
  background: #fff;
  background-repeat: no-repeat no-repeat;
  background-position: 95% 50%, 14% 50%;
  border: 1px solid var(--main-up-keep);
  border-radius: 40px;
}
.select-reviews:focus {
  outline: none;
}

.inputs-form {
  margin-right: -160px;
  z-index: 1;
  width: 10em;
  height: 4.8em;
  background-color: #3DB0D8;
  border-radius: 40px;
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (max-width: 600px) {
  .input-container {
    width: 95%;
  }

  .inputs-form {
    margin-right: -105px;
    width: 6.5em;
  }

  .select-reviews {
    padding-left: 7rem;
  }
}

@media only screen and (max-width: 420px) {
  .input-container {
    width: 100%;
  }
  .inputs-form {
    margin-right: -69px;
    width: 5.5em;
  }

  .select-reviews {
    padding-left: 4.5rem;
  }
}
