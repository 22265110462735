.form-container-input-create-company {
    width: 100%;
    margin: 1.5em 0;
}

.company-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-company {
  background-color: #fff;
  width: 35em;
  border-radius: 20px;
  margin-bottom: 2em;
}

.title-company {
  text-align: center;
}

.label-input-create-company {
  width: 30%;
  padding-left: 0;
}

.input-form-company {
  width: 70%;
  height: 3em;
  border-radius: 30px;
  border: 1px solid #ccc;
  padding-left: 2em;
  outline: none;
}

.fee {
  width: 65% !important;
}

.input-form-company:focus {
  border: 1px solid gray;
}

.card-body-company {
  margin: 0em 3em;
}

.btn-container-company {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2em;
}

.btn-submit-company {
  margin-top: 1em;
  width: 13em;
  height: 3.3em;
  border-radius: 40px;
  color: #fff;
  background-color: #fb9444;
  border: 2px solid #fb9444;
  transition: all 0.3s;
}

.btn-submit-company:hover {
  border: 2px solid #fb9444;
  background-color: #fff
}

input[type="file"] {
  display: none;
}

input[type="number"] {
  padding-right: 20px;
  padding-right: 2em;
}

.expiration-date {
  border-radius: 10px;
  width: 100%;
  border-width: 1px;
  border-color: rgb(235, 235, 235);
}

.expiration-date:focus {
  outline: none;
}

.zipcode-text {
  color: red;
  display: block;
  text-align: center;
}

.insurance-label {
  width: 50%;
  padding-left: 30px;
}

/* .react-datepicker-wrapper {
  width: 50% !important;
} */

.photo-container-create-company {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px 0;
}

.card-image-create-company {
  height: 100%;
  width: auto%;
}

[src=''] {
    visibility: hidden;
}
