.customer-section {
  background-color: var(--main-up-keep-secondary);
  padding: 1em 4em;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
    .customer-section {
        padding: 1em 2em;
    }
}

/* @media only screen and (min-width: 600px) {...}

@media only screen and (min-width: 768px) {...}

@media only screen and (min-width: 992px) {...}

@media only screen and (min-width: 1200px) {...} */
