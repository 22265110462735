.card-reset-password {
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 20px;
}
.match-password, .match-password-good {
  text-align: center !important;
  margin-bottom: 1rem !important;
  font-weight: bold;
}

.match-password {
  color: red;
}

.match-password-good {
  color: green;
}

.insert-text {
  margin-bottom: 5px;
}

.label-text {
  margin: 12px 5px;
  padding-bottom: 15px;
  font-weight: bold;
}

.form-reset-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 5em;
  align-items: flex-start;
}

.inputs-reset-password {
  min-width: 340px;
  height: 3.5em;
  border-radius: 50px;
  padding-left: 55px;
  border: 1px solid #C0C0C0;
  margin-top: 27px;
  width: 100%;
}

.icon-login-reset-password {
  width: .7em;
  height: 1em;
  margin-bottom: -62px;
  margin-left: 20px;
  z-index: 1;
}

.reset-button {
  margin-top: 15px;
  margin-bottom: 22px;
  width: 340px;
  height: 3.3em;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  border: 1px solid #fb9444;
  background-color: #fb9444;
}

.inputs-reset-password {
    outline: none;
}
