.contact-container {
  margin-top: 50px;
  max-width: 940px !important;
}

.contact-div {
  padding-bottom: 20px;
}

.contact-info {
  background-color: var(--main-up-keep-primary);
  max-width: 50% !important;
  justify-content: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-width: 370px !important;
}

.contact-logo-img {
  width: 100%;
  text-align: center;
  height: 100px;
  padding: 10px 20px;

}

.logo-contact {
  width: auto;
}

.contact-list {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-list .list-group-item {
  background-color: unset !important;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.list-group a:hover {
  color: var(--main-up-keep-primary) !important;
}

.text-info {
  display: flex;
  flex-direction: column;
}

.contact-list-group {
  padding-bottom: 220px;
}

.list-icon {
  margin-right: 25px;
  height: 20px;
  width: 40px;
  padding-top: 10px;
}

.text-info label {
  color: #FFFFFF;
}

.label-info {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.8) !important;
}

.contact-form {
  background-color: #FFFFFF;
  max-width: 50% !important;
  justify-content: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 370px !important;
}

.contact-title {
  width: 100%;
  height: 80px;
  text-align: left;
  padding: 10px 20px;
}

.title-label {
  font-size: 2.8rem !important;
}

.contact-inputs {
  width: 100%;
}

.form-input {
  border-color: rgba(61, 176, 216, 0.2) !important;
  height: 55px !important;
  border-radius: 2rem !important;
  padding: 10px 20px !important;
}

.form-input::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-textarea {
  height: 160px !important;
  padding-top: 22px !important
}

.form-submit {
  background-color: #F99746 !important;
  border-color: #F99746 !important;
  border-radius: 50px !important;
  width: 35%;
  height: 55px;
  float: right;
}

@media (max-width: 720px) {
  .contact-info {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .contact-form {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 8px;

  }

  .form-textarea {
    height: 55px !important;
    padding: 15px 20px 0px 20px !important;
  }

  .title-label {
    font-size: 2.2rem !important;
  }

  .contact-list-group {
    padding-bottom: 15px;
  }

  .form-submit {
    width: 100%;
    margin-bottom: 20px;
  }
}
