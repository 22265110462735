.confirm-container {
  margin-top: 50px;
  max-width: 1040px !important;
  background-color: #FFF;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 30px;
}

.confirmation-title {
  width: 100%;
}

.title-text {
  font-size: 2.1rem;
  padding-top: 35px;
}

.p-text {
  margin-bottom: 0rem;
  color: rgba(0, 0, 0, 0.6);
}

.confirm-img-div {
  width: 100%;
  height: 110px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.confirm-img {
  width: 100px;
  height: 100px;
}

.confirm-info {
  width: 100%;
  padding-bottom: 20px;
}

.list-info {
  list-style-type: none;
  border-bottom: 1px solid rgba(61, 176, 216, 0.2);
  padding-left: 0;

}

.list-info li {
  border-top: 1px solid rgba(61, 176, 216, 0.2);
  padding-top: 8px;
  padding-bottom: 3px;
}

.list-info li label {
  font-size: 1.1rem;
}

.fields {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  width: 50%;
  text-align: right;
  padding-right: 15px;
}

.fields-info {
  color: rgba(0, 0, 0, 0.6);
  width: 50%;
  text-align: left;
  padding-left: 15px;

}

.download-button-ticket {
  margin: 0 0 20px 0;
  width: 25%;
  height: 3.3em;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  border: 1px solid #fb9444;
  background-color: #fb9444;align-self: center;
}

@media (max-width: 768px) {
  .confirm-container {
    width: 70%;
    min-width: 330px;
  }

  .fields, .fields-info{
    width: 100%;
    text-align: left;
    padding: 0 0 0 3px;
    margin: 0;
  }

  .list-info li {
    padding-top: 3px;
  }

  .download-button-ticket {
    width: 75%;
  }
}
