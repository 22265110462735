.section-update-ticket {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.card-update-ticket {
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.div-title {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: var(--main-up-keep-secondary);
}

.card-title {
  padding: 20px 0;
  width: auto;
  margin: 0 !important;
}

.form-container-update {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.new-status-section {
  width: 100%;
  display: flex;
  margin: 20px 0;
  justify-content: center;
}

.text-new-status {
  width: 30%;
  text-align: center;
  padding-left: 0;
}

.div-select {
  width: 50%;
}

.new-status-select {
  border-color: var(--main-up-keep-secondary);
  border-radius: 10px;
  border-width: 2px;
  width: 200px;
}

.comment-section {
  width: 90%;
  display: flex;
  margin: 15px 0 0 0;
}

.div-comment {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 26px;
}

.text-comment {
  width: 30%;
  text-align: center;
  padding-left: 66px;
}

.text-area {
  width: 90%;
}

.button-submit-status {
  margin: 1em 0;
  width: 9em;
  height: 2.9em;
  border-radius: 40px;
  color: #fff;
  background-color: #fb9444;
  border: 2px solid #fb9444;
  transition: all 0.3s;
}
