.row-add-btn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2em;
}

.container-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 15em;
  height: 5em;
  background-color: #fff;
  border-radius: 50px;
  cursor: pointer;
}

.icon-btn {
  font-size: 1.48em;
  color: #fff;
}
.label-btn {
  transition: all 0.3s;
}
.btn-add {
    height: 3.8em;
    width: 3.8em;
    border-radius: 40px;
    border: 1px solid var(--main-up-keep);
    background-color: var(--main-up-keep);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}
.container-btn:hover .btn-add {
  background-color: #fb9444;
  border: 1px solid #fb9444;
  transition: all 0.3s;
}

.container-btn:hover .label-btn {
  color: #fb9444;
  transition: all 0.3s;
}
