.custom-input-calendar {
}

.input-calendar {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  background: #fff;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  background-size: 2em;
  height: 3.4em;
  width: 25em;
  padding: 0 1.5em;
  border-radius: 30px;
  border: 1px solid #c0c0c0;
  cursor: pointer;
}

.input-calendar:focus {
  outline: none;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .input-calendar {
    margin-top: 20px;
    height: 3.4em;
    width: 100%;
  }
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .input-calendar {
    margin-top: 20px;
    width: 100%;
  }
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .input-calendar {
    margin-top: 0;
    width: 22em;
    height: 3.4em;
  }
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
  .input-calendar {
    margin-top: 0;
    width: 100%;
    height: 3.4em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }

  .input-calendar {
    width: 100%;
  }
}
