.list-open-charge-info  li {
  padding-top: 8px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(61, 176, 216, 0.2);
}

.list-open-charge-info {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}

.fields-open-charge {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  width: 50%;
  text-align: right;
  padding-right: 15px;
}

.fields-open-charge-info {
  color: rgba(0, 0, 0, 0.6);
  width: 50%;
  text-align: left;
  padding-left: 15px;
}

.last {
  border: 0;
}

.list-open-charge-info  li:last-child {
  border-bottom: 1px solid rgba(249, 151, 70, 1);
}

.duration-time-select {
  width: 120px;
  height: 25px;
  font-size: 15px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  text-align-last: center;
  margin: 10px;
}

@media (max-width: 999px) {
  .fields-open-charge, .fields-open-charge-info {
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }
}
