.section-questionnaire {
  background-color: var(--main-up-keep-secondary);
  width: 100%;
  padding: 0 8em;
  padding-bottom: 3em;
}
.title-questionnaire {
  font-size: 3.2em;
  text-align: center;
  margin-bottom: 1em;
}
.card-questionnaire {
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2em;
  margin-bottom: 5em;
}
.form-questionnaire {
  width: 100%;
  padding: 0 1.5em;
}
.row-container {
  display: flex;
  padding: 2em 0;
  border-bottom: 1px solid #c0c0c0;
}
.label-input {
  font-size: 2.1em;
  width: 50%;
}

.container-btn-questionnaire {
  display: flex;
  padding-top: 2em;
  padding-bottom: 3em;
  position: relative;
  justify-content: center;
}

.btn-submit-questionnaire {
  position: absolute;
  top: 100px;
  background-color: #fb9444;
  width: 10em;
  height: 4em;
  border: 0;
  border-radius: 40px;
  color: #fff;
}

.btn-submit-questionnaire:hover {
  background-color: #fff;
  color: #fb9444;
  border: 2px solid #fb9444;
}

.btn-submit-questionnaire span {
  font-weight: bold;
  font-size: 1.2em;
}

.input-questionnaire {
  height: 3.8em;
  width: 25em;
  padding: 0 1.5em;
  border-radius: 30px;
  border: 1px solid #c0c0c0;
}

.input-questionnaire:focus {
  outline: none;
}

.select-questionnaire {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  background: #fff;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}
.container-select-questionnaire {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section-questionnaire {
    width: 100vw;
    padding: 3em 2em;
  }
  .row-container {
    flex-direction: column;
  }
  .label-input {
    font-size: 2em;
    text-align: center;
    width: 100%;
  }
  .input-questionnaire {
    margin-top: 20px;
    height: 3.8em;
    width: 100%;
  }
  .container-select-questionnaire {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .section-questionnaire {
    width: 100%;
    padding: 3em 2em;
  }
  .row-container {
    flex-direction: column;
  }
  .container-input-calendar {
    width: 100%;
  }
  .label-input {
    text-align: center;
    width: 100%;
  }
  .input-questionnaire {
    margin-top: 20px;
    width: 100%;
  }
  .container-select-questionnaire {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .section-questionnaire {
    padding: 3em 5em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .section-questionnaire {
    padding: 3em 7em;
  }
  .row-container {
    flex-direction: row;
  }
  .input-questionnaire {
    margin-top: 0;
    width: 22em;
  }
  .label-input {
    width: 50%;
  }
  .container-select-questionnaire {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .section-questionnaire {
    padding: 0 8em;
    padding-bottom: 3em;
  }
  .input-questionnaire {
    width: 25em;
  }
}
