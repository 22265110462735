.container-add-balance {
  width: 60%;
  margin: auto;
}

.add-balance-btn-container {
  width: 100%;
  margin:auto;
  display: flex;
  justify-content: center;
}

.button-sumbit-add-balance {
  margin: 1rem 0 1rem 0;
  width: 213px;
  height: 47px;
  background: #F99746;
  border: none;
  border-radius: 45px;
  color: #fff;
}

.id-found-text {
  text-align: center;
}

.calendar-cointainer {
  margin: .5rem 2.5rem;
}

@media only screen and (max-width: 999px) {
  .container-add-balance {
    width: 80%;
  }
}
