.item-time {
  width: 7em;
  height: 4em;
  border-radius: 40px;
  margin: 4px 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-time:hover {
  cursor: pointer;
}
.item-time span {
  font-weight: bold;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .item-time {
    width: 6em;
    height: 4em;
    margin: 4px 0.2em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
