.information-img-signup, .personal-form-signup {
  height: 500px;
}

.logo {
  height: 100%;
}

.buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.change-pass {
  background-color: var(--main-up-keep) !important;
  border-color: var(--main-up-keep) !important;
  padding: inherit;
}

@media (max-width: 739px) {
  .information-row {
    align-items: center;
    flex-direction: column;
  }

  .change-pass {
    width: 95%;
  }
}
