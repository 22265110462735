.payment-req-section {
  background-color: var(--main-up-keep-secondary);
  padding: 1em 4em;
}
.container-current-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}
.current-balance {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 21em;
  height: 5em;
  border-radius: 35px;
  border: 1px solid rgba(0,0,0,0.2);
}

.img-current {
  width: 3.8em;
  height: 3.8em;
  background-color: #fb9444;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-text-balance {
  display: flex;
  flex-direction: column;
  padding-right: 1em;
}
.label-balance {
  font-weight: bold;
  padding-left: 1em;
  font-size: .9em;
}
.container-current-balance .row-add-btn {
  padding-bottom: 0!important;
}