.services-title {
  text-align: center;
  color: var(--main-up-keep-primary);
  padding-top: 2%;
}

.service-container {
  margin-bottom: 30px;
}

.card-col {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-grow: 0 !important;
}

.services-row {
  margin-left: 5% !important;
}

@media (max-width: 777px) {
  .card-col {
    max-width: 250px !important;
  }

  .services-row{
    justify-content: center !important;
    width: 100%;
  }
}
