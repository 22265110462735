.ticket-div-container {
  width: 700px;
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
}

.div-title {
  width: 90%;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: var(--main-up-keep-secondary);
}

.card-title {
  padding: 20px 0;
  width: auto;
  margin: 0 !important;
}

.card-ticket-info {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}

.list-ticket-info li {
  padding-top: 8px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(61, 176, 216, 0.2);
}

.list-ticket-info {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}


.fields-ticket {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  width: 50%;
  text-align: right;
  padding-right: 15px;
}

.fields-ticket-info {
  color: rgba(0, 0, 0, 0.6);
  width: 50%;
  text-align: left;
  padding-left: 15px;
}


@media (max-width: 999px) {
  .ticket-div-container {
    width: 400px;
  }

  .card-ticket-info {}

    .fields-ticket, .fields-ticket-info {
      text-align: left;
      width: 100%;
      padding-left: 15px;
    }
}
