.companies-section {
  background-color: var(--main-up-keep-secondary);
  padding: 1em 4em;
  display: flex;
  justify-content: center;
}

.list-companies-filters-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.reset-values-list-companies {
  background-color: var(--main-up-keep-primary);
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 160px;
  height: 30px;
  margin-bottom: 1.8em;
}

.apply-filter-list-companies {
  background-color: #F99746;
  border-color: #F99746;
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 160px;
  height: 30px;
  margin-bottom: 1.8em;
}

.status-filter-company, .name-filter-company {
  width: 150px;
  height: 35px;
  font-size: 13px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  text-align-last: center;
  margin-bottom: 2em;
}


  @media only screen and (max-width: 600px) {
      .companies-section {
          padding: 1em 2em;
      }
  }
