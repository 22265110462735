.tool-view {
  /* margin-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
}

.handle-text-tools {
  cursor: pointer;
  transition: all 0.4s;
}

.handle-text-tools:hover {
  background-color: rgba(0,0,0,.15);
  border-top-left-radius: 34px;
  border-bottom-left-radius: 34px;
  transition: all 0.4s;
}

.container-section-tools {
  padding: 3em 12em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-tools {
  width: 100%;
  height: 40em;
  background-color: #fff;
  display: flex;
  border: 1px solid rgba(0,0,0,.3);
  border-radius: 14px;
  border-left: 20px solid var(--main-up-keep);
}

.col-card-tools {
  width: 100%;
  height: 100%;
}

.container-text-tools {
  padding-left: 1em;
  padding-top: 1em;
}

.item-text-section-tools {
  display: flex;
  margin-bottom: 15px;
}

.number-item-tools {
  border: 10px solid #fff;
  font-size: 1.5em;
  background-color: var(--main-up-keep);
  color: #fff;
  border-radius: 100%;
  text-align: center;
  min-width: 3em; /* adjusted */
  min-height: 3em;
  max-width: 3em;
  margin-right: 10px;
  padding-top: 7px;
  font-weight: bold;
}

.text-item-tools {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container-section-tools {
    padding: 3em 1.2em;
  }
  .card-tools {
    flex-direction: column;
    height: initial;
    border-left:1px solid rgba(0,0,0,.3);
  }
  .col-card-tools {
    width: 100%;
    padding-bottom: 2em;
  }
}
