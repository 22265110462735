.section-booking-reschedule {
  background-color: var(--main-up-keep-secondary);
  width: 100%;
  padding: 0 8em;
  padding-bottom: 3em;
}

.card-booking-reschedule {
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 5em;
  padding: 0 1em;
}
.text-card-booking-reschedule {
  font-size: 3em;
  text-align: center;
  padding: 0.8em 0;
}
.container-date-reschedule {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container-time-reschedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.list-items-time-reschedule {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2em;
  border-bottom: 1px solid #c0c0c0;
}
.btn-section-booking-reschedule {
  margin: 3em 0;
  background-color: #fb9444;
  width: 12em;
  height: 4em;
  border: 0;
  border-radius: 40px;
  color: #fff;
}
.btn-section-booking-reschedule:hover {
  background-color: #fff;
  border: 2px solid #fb9444;
  color: #fb9444;
}

.btn-section-booking-reschedule span {
  font-weight: bold;
  font-size: 1.2em;
}
.message-not-found-reschedule h3 {
  text-align: center;
}

.container-check-justified {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2em;
  user-select: none;
  cursor: pointer;

}
.label-check-justified {
  padding-right: 2em;
  font-size: 1.5em;
  margin-bottom: 0;
}

#justified {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.8); /* IE */
  -moz-transform: scale(1.8); /* FF */
  -webkit-transform: scale(1.8); /* Safari and Chrome */
  -o-transform: scale(1.8); /* Opera */
  transform: scale(1.8);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section-booking-reschedule {
    width: 100vw;
    padding: 1em 2em;
  }
  .card-booking-reschedule {
    padding: 0 1em;
  }
  .list-items-time-reschedule {
    justify-content: inherit;
  }
  .btn-section-booking-reschedule {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .section-booking-reschedule {
    padding: 0 5em;
    padding-bottom: 3em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .section-booking-reschedule {
    padding: 0 8em;
    padding-bottom: 3em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
