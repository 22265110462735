.icon-star {
  width: 1.7em;
  height: 1.7em;
  margin: 0 4px;
}
.item-review {
  width: 90%;
  display: flex;
  background-color: var(--main-up-keep-primary);
  border-radius: 20px;
  margin: 0.8em 0;
}
.col-user-review {
  min-width: 10%;
  padding: 2em 0;
  text-align: right;
}
.container-user {
  height: 100%;
  border-right: 1px solid #c0c0c7;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.name-user-review {
  color: #fff;
}
.col-description-review {
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}
.date-description {
  color: var(--main-up-keep);
  font-weight: bold;
}
.description-review {
  color: #fff;
}
.btn-show-text {
  display: flex;
  justify-content: center;
  padding: 2em 0;
}
.img-arrow-review {
  width: 1.5em;
}
.show-arrow {
  display: none;
}
.active-arrow {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.inactive-arrow {
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .item-review {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .col-user-review {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .container-user {
    border: 0;
    width: 80%;
    border-bottom: 1px solid #c0c0c7;
    padding-bottom: 2em;
  }
  .col-description-review {
    padding: 0.5em 2em;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
  .description-review {
    line-height: 31px;
  }
  .show-arrow {
    display: flex;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .item-review {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .col-user-review {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .container-user {
    border: 0;
    width: 80%;
    border-bottom: 1px solid #c0c0c7;
    padding-bottom: 2em;
  }
  .col-description-review {
    padding: 0.5em 2em;
    justify-content: center;
    align-items: center;
    
  }
  .description-review {
    line-height: 31px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .item-review {
    flex-direction: row;
  }
  .container-user {
    height: 100%;
    border: 0;
    border-right: 1px solid #c0c0c7;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .col-user-review {
    padding: 2em 0;
    text-align: right;
  }
  .col-description-review {
    padding: 2em 1em;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .description-review {
    line-height: inherit;
  }
}
