.tickets-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.services-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.header-text-services {
  width: 90%;
  display: flex;
  padding-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(61, 176, 216, 0.2);
}

.fields-info-services {
  width: 50%;
  text-align: left;
  padding-left: 15px;
  padding-right: 3px;
  border-right: 1px solid rgba(61, 176, 216, 0.2);
  font-weight: bold;
}

.fields-info-history-header {
  width: 50%;
  text-align: left;
  padding-left: 20px;
  padding-right: 3px;
  border-right: 1px solid rgba(61, 176, 216, 0.2);
  font-weight: bold;
  margin-bottom: 10px;
}

.last {
  border: 0;
}

.elements-info {
  width: 90%;
}

@media (max-width: 999px) {
  .services-detail {
    width: 400px;
  }
}
