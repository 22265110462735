.balance-section {
  text-align: center;
  display: flex;
  justify-content: center;
}

.balance-div {
  display: flex;
  flex-wrap: wrap;
}

.add-balance-button {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

.label-current-balance-view {
  padding-top: 30px;
  font-size: 1.5rem;
}

.filters-container-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
}

.balance-filters-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  margin: 10px auto;
  justify-content: space-around;
}

.buttons-cointainer-balance {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%
}

.current-balance-div {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 10px auto 10px 10%;
}

.reset-balance-values, .download-excel {
  background-color: var(--main-up-keep-primary);
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 160px;
  height: 30px;
  margin: 10px;
}

.download-excel {
  border: 1px solid #fb9444;
  background-color: #fb9444;
}

.calendar-filter {
  border: 0;
  border-radius: 10px;
}

.calendar-filter:focus {
  outline: none;
}

.filter-by-reference {
  border: 0;
  border-radius: 5px;
}

.filter-by-reference:focus {
  outline: none;
}

@media (max-width: 999px) {
  .balance-filters-container {
    flex-direction: column;
  }

  .filter-by-reference {
    margin: 20px auto;
  }

  .add-balance-button {
    justify-content: center;
  }
}
