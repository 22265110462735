.list-service-info  li {
  padding-top: 8px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(61, 176, 216, 0.2);
}

.list-service-info {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}

.fields-service {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  width: 50%;
  text-align: right;
  padding-right: 15px;
}

.fields-service-info {
  color: rgba(0, 0, 0, 0.6);
  width: 50%;
  text-align: left;
  padding-left: 15px;
}

.last {
  border: 0;
}

.list-history-info  li:last-child {
  border-bottom: 1px solid rgba(249, 151, 70, 1);
}

@media (max-width: 99px) {
  .fields-service, .fields-service-info {
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }
}
