.filters-container-tickets {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companies-filters-container {
  width: 85%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.companies-filter, .status-filter {
  width: 150px;
  height: 35px;
  font-size: 13px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  text-align-last: center;
  margin-bottom: 10px;
}

.reset-values {
  background-color: var(--main-up-keep-primary);
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 160px;
  height: 30px;
  margin-bottom: 10px;
}

.order-by-date-button, .order-by-price-button {
  background-color: #fff;
  border-radius: 4px;
  border: none;
  width: 150px;
  height: 35px;
  margin: 0 5px 10px 5px;
}

.order-by-price-button {
  width: 100px;
}
