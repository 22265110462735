.upload-section-csv {
  width: 800px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 100px;
}

.browse-div-csv {
  width: 100%;
  background-color: #fff;
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-csv-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.selection-csv-div {
  width: 98%;
  height: 90%;
  border-radius: 10px;
  border-color: var(--main-up-keep);
  border-style: solid;
  top: 10px;
  display: flex;
  flex-direction: row;
}

.browse-text {
  width: 30%;
  height: 95%;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-style: solid;
  border-right-color: var(--main-up-keep);
}

.select-csv-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(61, 176, 216, 0.2);
}

.text {
  font-size: 1.7em;
  margin-bottom: 0;
  color: var(--main-up-keep);
}

.custom-csv-upload {
  width: 100%;
  padding:  0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-button-csv-section {
  margin: 20px 0 50px 0;
  width: 100%;
}

.upload-button-csv {
  width: 20%;
  border-radius: 50px;
  background-color: #F99746;
  border-color: #F99746;
  font-size: 0.7rem;
}

.upload-text {
  margin-right: 35%;
}

.arrow-select-file {
  margin-left: 30%;
}

.csv-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  width: 300px;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px 0;
}

.card-csv {
  height: 200px;
  width: 200px;
}

input[type="file"] {
  display: none;
}

[src=''] {
    visibility: hidden;
}

@media (max-width: 900px) {
  .upload-section {
    width: 600px;
  }

  .upload-text {
    margin-right: 25%;
  }

  .arrow-select-file {
    margin-left: 25%;
  }
}

@media (max-width: 767px) {
  .upload-section {
    width: 450px;
  }

  .upload-text {
    margin-right: 25%;
  }

  .arrow-select-file {
    margin-left: 15%;
  }
}
