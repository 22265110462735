.container-btn-action {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
}

.btn-action {
  width: 10em;
  height: 3em;
  border-radius: 10px;

  background-color: #fb9444;
  border: 2px solid #fb9444;
  color: #fff;
}

.btn-action:hover {
  opacity: 0.7;
}
