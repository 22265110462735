.section-list-services {
  background-color: var(--main-up-keep-secondary);
  padding: 1em 4em;
  display: flex;
  justify-content: center;
}
.container-btn-services {
  display:flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  margin-right: 2em;
}
