.container-check-express {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2em;
  user-select: none;
  cursor: pointer;
}
.label-check-express {
  font-size: 1.5em;
  margin-bottom: 0;
}

#express {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.8); /* IE */
  -moz-transform: scale(1.8); /* FF */
  -webkit-transform: scale(1.8); /* Safari and Chrome */
  -o-transform: scale(1.8); /* Opera */
  transform: scale(1.8);
}
