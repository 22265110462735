.nav-item{
  margin: 0 1rem 0 1rem;
  cursor: pointer;
}

.nav-link{
  color: var(--main-up-keep-secondary) !important;
  font-weight: bold;
}

@media only screen and (max-width: 830px) {
  .nav-item{
    margin: 0 0.4rem;
  }
}
