.add-new-linked-service-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-linked-service-actions {
  display: flex;
  flex-direction: row;
  margin: auto 30px;
  padding-bottom: 1em;
}

.add-new-service-select {
  width: 150px;
  height: 35px;
  font-size: 13px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  text-align-last: center;
  margin: 10px 0;
}

.add-service-button {
  background-color: var(--main-up-keep-primary);
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 160px;
  height: 30px;
  margin: 10px 10px;
}


@media only screen and (max-width: 768px) {
  .add-new-linked-service-div, .container-linked-service-actions {
    width: 80%;
    margin: auto;
    align-items: center;
  }

  .add-new-linked-service-div {
    flex-direction: column-reverse;
  }

  .container-linked-service-actions {
    flex-direction: column;
  }
}
