.about-us {
  margin-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

.container-whats-is {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.goal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.whats-is-img {
  object-fit: cover;
  height: 350px;
  width: 100%;
}

.container-story {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--main-up-keep-primary) !important;
  padding-bottom: 15px;
}

.box {
  width: 50%;
  height: 100%;
  min-width: 270px;
}

.picture {
  object-fit: cover;
  margin: 20px 20px;
  width: 80%;
}

.goal-text {
  color: var(--main-up-keep);
  font-size: 4.3rem;
}

.story-behind {
  color: var(--main-up-keep-secondary);
  font-size: 1.3rem;
  margin: 0 10%;
  width: 95%;
}

.box-story {
  padding-top: 30px;
  width: 50%;
  height: 100%;
  min-width: 270px;
}

.story-behind-title {
  font-size: 3.5rem;
}

.whats-is {
  font-size: 4.3rem;
  padding-right: 5%;
}

.whats-is-text {
  font-size: 1.5rem;
  padding-right: 5%;
}

@media (max-width: 860px) {
  .whats-is-text {
    font-size: 1rem;
    padding-right: 5%;
  }

  .whats-is {
    font-size: 2.8rem;
    padding-right: 5%;
    padding-left: 10%;
    text-align: center;
  }

  .container-story {
    justify-content: center;
  }

  .box-story {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lead, .goal-text {
    padding-left: 10%;
  }

  .box {
    width: 90%;
  }

  .story-behind-title {
    font-size: 2.6rem;
    text-align: center;
  }
}
