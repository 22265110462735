.card {
  text-align: center;
  align-items: center;
  border: unset !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem !important;
  height: fit-content;
  width: 230px;
}

.card-body {
  padding: 0rem 0rem 0rem 0rem !important;
}

.card-div {
  margin-top: 5%;
  margin-bottom: 5%;
}

.card-text {
  text-align: left;
}

.card-title {
  display: flex;
  align-items: center;
  min-height: 80px;
  margin: 0 0 0 6px !important;
  text-align: justify;
  width: 40%;
}

.img-container {
  background-color: var(--main-up-keep-primary);
  border-radius: 50% !important;
  width: 76px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.row {
  justify-content: flex-start;
}

.img-title {
  max-width: 60% !important;
}

.title-div {
  width: 100%;
  padding: 10px 5px 0px 5px;
  margin-bottom: 15px;
  max-height: 90px;
  justify-content: center;
}

.cards-container {
  padding: 2% 2%;
}

.book-button {
  background-color: #F99746 !important;
  border-color: #F99746 !important;
  border-radius: 50px !important;
  width: 70%;
  margin-bottom: 14px;
}

.book-button:focus:focus-within:focus-visible {
  outline: 0;
}

.list {
  color: var(--main-up-keep-primary);
  list-style: none;
  padding-left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.list li {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 3px;
  padding-bottom: 3px;
}

.list li::before {
  content:"";
  border-radius: 50%;
  border-style: solid;
  width: 6px;
  height: 6px;
  margin-right: 0.3em;
  margin-bottom: 2px;
  position: relative;
  display: inline-block;
  color: var(--main-up-keep);
}

.card-title label {
  font-size: 0.8rem;
  overflow-wrap: break-word;
}

.arrow {
  width: 14px;
  height: 14px;
  float: left;
  border-radius: 2px;
  border: solid #F99746;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-width: 0px 3px 3px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  display: none;
}

.arrow:hover {
  border-width: 0 6px 6px 0;
}

.collapse-card {
  display: none;
}

.collapse-card-open {
  width: 82%;
  display: unset;
}


@media (max-width: 867px) {
  .arrow {
    display: unset;
  }
}
