.wrapper{
  min-height: 100vh;
  height: auto;
  background-color: var(--main-up-keep-secondary);
}

body {
  margin: 0;
}
.container-view {
  min-height: 30vh;
}

.section-create {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-create {
  background-color: #fff;
  width: 35em;
  border-radius: 20px;
  margin-bottom: 2em;
}
.card-head-create {
  padding-top: 2em;
  padding-bottom: 0.8em;
}
.title-card-create {
  text-align: center;
}
.card-body-create {
  margin: 0em 3em;
}
.form-container-input {
  width: 100%;
  margin: 1.5em 0;
}
.input-form-create {
  width: 100%;
  height: 3em;
  border-radius: 30px;
  border: 1px solid #ccc;
  padding-left: 2em;
  outline: none;
}
.input-form-create:disabled {
  cursor:no-drop;
  background-color: #ddd;
  border-color: #ddd;
}
.input-form-create:focus {
  border: 2px solid #aaa;
}

.text-form-create {
  width: 100%;
  min-height: 4em;
  max-height: 10em;
  padding-top: 10px;
  padding-left: 28px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
}
.text-form-create:focus {
  border: 1px solid gray;
}
.btn-container-create {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2em;
}
.btn-submit-create {
  margin-top: 1em;
  width: 13em;
  height: 3.3em;
  border-radius: 40px;
  color: #fff;
  background-color: #fb9444;
  border: 2px solid #fb9444;
  transition: all 0.3s;
}
.btn-submit-create:hover {
  border: 2px solid #fb9444;
  background-color: #fff;
}
.label-btn-submit {
  font-size: 1.2em;
  font-weight: bold;
}
.btn-submit-create:hover .label-btn-submit {
  color: #fb9444;
  transition: all 0.3s;
}
.select-create {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  background: #fff;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}

.select-create:disabled {
  background-color: #cacaca;
}

.label-input-create {
  padding-left: 1em;
  padding-bottom: .5em;
}
.container-input-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}