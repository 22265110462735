.div-title-update-value {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: var(--main-up-keep-secondary);
}

.update-value-section {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px 0 0 0;
}

.text-comment-new-value {
  width: 50%;
  text-align: center;
  margin: auto;
}

.text-area-new-value {
  width: 50%;
}

.button-submit-update-value {
  margin: 1em 0;
  width: 9em;
  height: 2.9em;
  border-radius: 40px;
  color: #fff;
  background-color: #fb9444;
  border: 2px solid #fb9444;
  transition: all 0.3s;
}

@media (max-width: 999px) {
  .card-update-ticket {
    width: 320px;
  }

  .update-value-section {
    justify-content: center;
  }

  .text-comment-new-value, .text-area-new-value {
    width: 80%;
  }
}
