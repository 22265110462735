.information-container {
  margin-top: 50px;
  max-width: 1040px !important;
}

.information-div {
  padding-bottom: 20px;
}

.form-input {
  border-color: rgba(61, 176, 216, 0.2) !important;
  height: 55px !important;
  border-radius: 2rem !important;
  padding: 10px 20px !important;
}

.information-img {
  background-color: var(--main-up-keep-primary);
  max-width: 50% !important;
  display: flex !important;
  justify-content: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  min-width: 370px !important;
  padding: 0px !important;
}

.information-logo-img {
  width: 100%;
  text-align: center;
  height: 100%;

}

.logo {
  padding: 0 !important;
  border-radius: 8px 0 0 8px;
  width: 100%;
}

.personal-form {
  background-color: #FFFFFF;
  max-width: 50% !important;
  justify-content: left;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 370px !important;
  padding: 20px 30px 0px;
}

.titles {
  width: 100%;
  height: 80px;
  text-align: left;
  padding: 0 20px !important;
}

.title-label {
  font-size: 2.6rem !important;
}

.check-terms {
  border-top: 1px solid rgba(61, 176, 216, 0.2);
  border-bottom: 1px solid rgba(61, 176, 216, 0.2);
  padding: 10px 0px 10px 10px;
}

.check-input {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  margin-left: -0.1rem;
}

/*****/
.label-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  margin-top: 5px;
  background-color: none;
  border: 1px solid rgba(61, 176, 216, 0.2);
  border-radius: 5px;
}

/* On mouse-over, add a main color background color */
.label-check:hover input ~ .checkmark {
  background-color: var(--main-up-keep);
}

/* When the checkbox is checked, add a blue background */
.label-check input:checked ~ .checkmark {
  background-color: var(--main-up-keep);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-check .checkmark:after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.terms-service {
  color: var(--main-up-keep);
}

.form-submit {
  background-color: #F99746 !important;
  border-color: #F99746 !important;
  border-radius: 50px !important;
  width: 35%;
  height: 55px;
  float: right;
  margin: 20px 0;
}

.form-input::-webkit-outer-spin-button,
.form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-input {
  -moz-appearance: textfield;
}

.checkout-form-container{
  margin-top: 1rem;
  margin-bottom: 2rem;;
}

@media (max-width: 739px) {
  .information-img {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 300px;
  }

  .logo {
    height: 100%;
    padding: 5px 0;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }

  .personal-form {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 8px;
    padding: 10px 10px 0;
  }

  .titles {
    text-align: center;
    margin-bottom: 10px;
    height: 50px;
  }

  .label-check {
    font-size: 18px;
  }

  .title-label {
    font-size: 2.2rem !important;
  }

  .form-submit {
    width: 95%;
    margin-bottom: 20px;
    font-size: 25px;
  }

  .checkmark {
    margin-top: 0;
  }
}
